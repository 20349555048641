$('body').on('click', '[data-scroll-to]', function(e) {
  const el = document.querySelector($(this).data("scroll-to"))
  const scrollPosition = el.getBoundingClientRect().top + window.pageYOffset - 10;
  window.scrollTo({top: scrollPosition, behavior: 'smooth'});
  document.querySelector($(this).data("focus-input")).focus()
});

document.addEventListener('DOMContentLoaded', function () {
  const birthCountryField = document.querySelector('.user_account_birth_country select');
  if(birthCountryField) {
    const birthDistrictField = document.querySelector('.user_account_birth_district_number select');
    const birthDistrictContainer = document.querySelector('.birth_district_number_input');

    updateBirthDistrict = (e) => {
      let newDistrictValue = birthDistrictField.value;

      if(birthCountryField.value !== "FR") {
        newDistrictValue = "99";
        birthDistrictContainer.classList.add("d-none");
      } else {
        if(birthDistrictField.value === "99") {
          newDistrictValue = "";
        }
        birthDistrictContainer.classList.remove("d-none");
      }
      birthDistrictField.value = newDistrictValue;
    }
    birthCountryField.addEventListener('change', updateBirthDistrict);
    updateBirthDistrict();
  }

  const passwordField = document.querySelector('input.password');
  if(passwordField) {
    const displayPasswordHint = () => {
      const passwordHint = document.querySelector('.password-hint');

      if(passwordHint) {
        passwordHint.classList.remove('d-invisible');
      }
    }

    passwordField.addEventListener('focus', displayPasswordHint);
  }

  const fiscalCountryField = document.querySelector("select.fiscal_country_field")
  if(fiscalCountryField) {
    const countryWarnings = document.querySelectorAll('.country-warning');
    document.addEventListener('change', e => {
      // find all .country-warning classes, and hide them, then show the one that matches the selected country
      countryWarnings.forEach(warning => warning.classList.add('d-none'));
      const selectedCountryWarning = document.querySelector(`.country-warning-${e.target.value.toLowerCase()}`);
      if(selectedCountryWarning) {
        selectedCountryWarning.classList.remove('d-none');
      }
    })
  }
});
