document.addEventListener("DOMContentLoaded", () => {
  const futureBalanceElement = document.getElementsByClassName("future-balance")[0];
  if (futureBalanceElement) {
    const amountInputEur = document.getElementById("account_refill_amount_eur"); 

    const initialBalance = parseFloat(futureBalanceElement.getAttribute("data-balance") || 0);

    const updateFutureBalance = () => {
      const amount = parseFloat(amountInputEur.value.replace(",", ".")*100);
      const futureBalance = isNaN(amount) ? initialBalance : (initialBalance + amount)/100;
      futureBalanceElement.innerHTML = futureBalance.toFixed(2);
    };
    amountInputEur.addEventListener("keyup", updateFutureBalance);
    updateFutureBalance();
  };
}); 